import * as amplitude from '@amplitude/analytics-browser';
import { createStyles, Stack, Text, Title } from '@mantine/core';
import { GetServerSidePropsContext } from 'next';
import { getServerSession } from 'next-auth';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';
import validator from 'validator';

import { TwoColImageLayout } from '@/components/onboarding/TwoColImageLayout';
import {
  CottageButton,
  CottagePasswordInput,
  CottageTextInput,
} from '@/components/shared/default';
import { showNotification } from '@/components/shared/Notification';
import { ScheduledMaintenanceBanner } from '@/components/shared/ScheduledMaintenanceBanner';
import { resetPassword, sendForgotPasswordEmail } from '@/lib/api/client/users';
import { ANALYTICS_EVENTS } from '@/lib/constants/analyticsEvents';

import { authOptions } from '../api/auth/[...nextauth]';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);

  // If the user is already logged in, redirect.
  if (session) {
    return { redirect: { destination: context.query.callbackUrl || '/' } };
  }

  return { props: {} };
}

const useStyles = createStyles(() => ({
  mockLink: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [isTokenSignInAttempted, setIsTokenSignInAttempted] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [error, setError] = useState('');
  const [signInLoading, setSignInLoading] = useState(false);
  const [sendRecoveryEmailLoading, setSentRecoveryEmailLoading] =
    useState(false);
  const [callbackUrl, setCallbackUrl] = useState('/'); // Default to home

  const { classes } = useStyles();

  const router = useRouter();

  const handleTokenSignIn = async () => {
    setSignInLoading(true);
    const res = await signIn('credentials', {
      redirect: false,
      authToken,
    });

    if (res?.ok) {
      amplitude.setUserId(username);
      void router.push(callbackUrl);
    } else if (res?.status === 401) {
      setError('Invalid signin credentials');
      setSignInLoading(false);
    } else {
      setError('Error contacting server. Please try again later');
      setSignInLoading(false);
    }
  };

  const handleSignIn = async () => {
    setSignInLoading(true);
    const res = await signIn('credentials', {
      redirect: false,
      username,
      password,
    });

    if (res?.ok) {
      amplitude.setUserId(username);
      void router.push(callbackUrl);
    } else if (res?.status === 401) {
      setError('Invalid username or password');
      setSignInLoading(false);
    } else {
      setError('Error contacting server. Please try again later');
      setSignInLoading(false);
    }
  };

  useEffect(() => {
    const hasToken = !!router.query.token;
    if (hasToken) {
      setPasswordReset(true);
    }

    const queryAuthToken = router.query.authToken;
    if (typeof queryAuthToken === 'string' && queryAuthToken) {
      setAuthToken(queryAuthToken);
    }

    const queryCallbackUrl = router.query.callbackUrl;
    if (typeof queryCallbackUrl === 'string' && queryCallbackUrl) {
      setCallbackUrl(queryCallbackUrl);
    }
  }, [router]);

  useEffect(() => {
    if (authToken && !isTokenSignInAttempted) {
      // Attempt to sign in with the impersonation token
      void handleTokenSignIn();
      setIsTokenSignInAttempted(true);
    }
  }, [authToken, isTokenSignInAttempted, handleTokenSignIn]);

  const toggleForgotPassword = () => {
    setError('');
    setForgotPassword(!forgotPassword);
  };

  const sendRecoveryEmail = async () => {
    if (!validator.isEmail(username)) {
      setError('Please enter a valid email address.');
    } else {
      setSentRecoveryEmailLoading(true);
      try {
        await sendForgotPasswordEmail({ userEmail: username });
      } catch (error) {
        amplitude.track({
          event_type: ANALYTICS_EVENTS.FORGOT_PASSWORD_ERROR,
          event_properties: {
            errorMessage: error,
          },
        });
      } finally {
        showNotification({
          title: 'Recovery email sent',
          message:
            'If you have user account, you will be sent a password recovery ' +
            'email.',
        });
        setSentRecoveryEmailLoading(false);
      }
    }
  };

  const handlePasswordReset = async () => {
    setError('');
    if (!validator.isStrongPassword(password)) {
      setError(
        'Password must be at least 8 characters long, with at least one uppercase character, number, and symbol'
      );
    } else {
      let { token, id, role } = router.query;
      // Force string type for all query params
      token = token?.toString() ?? '';
      id = id?.toString() ?? '';
      role = role?.toString() ?? '';

      try {
        await resetPassword({
          password: password,
          token,
          userId: id,
          userRole: role,
        });
        showNotification({
          title: 'Success',
          message: `Your password has been successfully updated.`,
        });
        router.push('/');

        setTimeout(() => {
          router.reload();
        }, 1000);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An error occurred. Please try again later.');
        }
      }
    }
  };

  if (passwordReset) {
    return (
      <TwoColImageLayout
        leftImagePath="/images/cottage-adu-aerial.png"
        imageAltText="Image of the interior of an adu"
      >
        <Stack>
          <Title order={1}>Reset Your Password</Title>
          {error && <Text color="red.6">{error}</Text>}
          <CottagePasswordInput
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
          />
          <CottageButton onClick={() => void handlePasswordReset()} fullWidth>
            Reset Password
          </CottageButton>
        </Stack>
        <ScheduledMaintenanceBanner />
      </TwoColImageLayout>
    );
  }

  const forgotPasswordComponent = (
    <Stack spacing="xl">
      <Stack spacing="xxxs">
        <Title order={1}>Recover Password</Title>
        <Text variant="subtitle">
          Please enter the email associated with your account and we will send
          you a recovery email.
        </Text>
        {error && <Text color="red.6">{error}</Text>}
      </Stack>
      <Stack>
        <CottageTextInput
          label="Email"
          name="username"
          autoComplete="username"
          type="username"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
      </Stack>
      <Stack spacing="md">
        <CottageButton
          loading={sendRecoveryEmailLoading}
          onClick={() => void sendRecoveryEmail()}
        >
          Recover Password
        </CottageButton>
        <Text
          className={classes.mockLink}
          onClick={() => toggleForgotPassword()}
        >
          <RiArrowLeftLine /> Back
        </Text>
      </Stack>
      <ScheduledMaintenanceBanner />
    </Stack>
  );

  const signInComponent = (
    <Stack spacing="xxxl">
      <Stack spacing="xxs">
        <Text variant="subtitle">Welcome back!</Text>
        <Title order={1}>Sign In</Title>
      </Stack>
      {error && <Text color="red.6">{error}</Text>}
      <Stack spacing="md">
        <CottageTextInput
          label="Email"
          name="username"
          autoComplete="username"
          type="username"
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void handleSignIn();
            }
          }}
          value={username}
        />
        <CottagePasswordInput
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              void handleSignIn();
            }
          }}
          label="Password"
        />
      </Stack>
      <Stack>
        <CottageButton
          fullWidth
          loading={signInLoading}
          onClick={() => {
            amplitude.track({
              event_type: ANALYTICS_EVENTS.USER_SIGNIN_BUTTON_CLICKED,
              event_properties: {
                username,
              },
            });
            void handleSignIn();
          }}
        >
          Sign In
        </CottageButton>
        <Text
          className={classes.mockLink}
          onClick={() => {
            amplitude.track(ANALYTICS_EVENTS.FORGOT_PASSWORD_LINK_CLICKED);
            toggleForgotPassword();
          }}
        >
          Forgot Password?
        </Text>
      </Stack>
      <ScheduledMaintenanceBanner />
    </Stack>
  );

  return (
    <TwoColImageLayout
      leftImagePath="/images/cottage-adu-aerial.png"
      imageAltText="Image of the interior of an adu"
    >
      {forgotPassword ? forgotPasswordComponent : signInComponent}
    </TwoColImageLayout>
  );
}

SignIn.hideNav = true;
